import { Map } from 'maplibre-gl';

export const MapOnMissingImage = (image: { id: string; target?: Map }, cdnUrl: string) => {
	if (!image.target) {
		return;
	}

	const map = image.target;
	const id = image.id;
	const url = `${cdnUrl}/sprites/public/sdf/sprites/${image.id}.png`;

	if (!map.hasImage(id)) {
		map.loadImage(url).then(img => {
			if (!img) {
				throw new Error(`Image could not be loaded for, ${url}`);
			}

			// the Map fires the same missing image event multiple times
			// then throws an error: "An image named "{{id}}" already exists."
			if (!map.hasImage(id)) {
				if (id === 'scale' || id === 'rotate') {
					map.addImage(id, img.data, {
						sdf: false,
						pixelRatio: 1
					});
				} else {
					map.addImage(id, img.data, {
						sdf: true,
						pixelRatio: 1
					});
				}
			}
		});
	}
};
