import maplibregl from 'maplibre-gl';
import { Protocol } from 'pmtiles';

/**
 * Setup the PMTiles maplibre protocol
 * To handle pmtiles:// files
 */
export const setupPMTilesMapLibre = (): void => {
	const protocol = new Protocol();
	maplibregl.addProtocol('pmtiles', protocol.tile);
};
